import React, { createContext, useState, useEffect } from 'react';
import I18n from '../i18n';

const LanguageContext = createContext();

const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(I18n.locale);

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || 'he';
    setLanguage(savedLanguage);
    I18n.locale = savedLanguage;
    document.body.dir = savedLanguage === 'he' || savedLanguage === 'ar' ? 'rtl' : 'ltr';
  }, []);

  const handleLanguageChange = (e) => {
    const selectedLanguage = e.target.value; // Extract the selected value
    setLanguage(selectedLanguage);
    I18n.locale = selectedLanguage;
    localStorage.setItem('language', selectedLanguage); // Save the selected language in localStorage
    document.body.dir = selectedLanguage === 'he' || selectedLanguage === 'ar' ? 'rtl' : 'ltr'; // Set the text direction
  };
  

  return (
    <LanguageContext.Provider value={{ language, handleLanguageChange }}>
      {children}
    </LanguageContext.Provider>
  );
};

export { LanguageProvider, LanguageContext };
