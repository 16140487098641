import React from 'react';
import '../css/Tooltip.css'; // Create and import a CSS file for custom styles

const Tooltip = ({ content, children }) => {
  return (
    <span className="custom-tooltip">
      {children}
      <div className="tooltip-content">
        {content.split('\n').map((line, index) => (
          <div key={index}>{line}</div>
        ))}
      </div>
    </span>
  );
};

export default Tooltip;
