export default {
  basic: {
    loading: 'טוען...',
    selectLanguage: 'בחר שפה',
  },
  navbar: {
    home: 'דף הבית',
    members: 'אזור אישי',
    account: 'החשבון שלי',
    practice: 'מערכת תרגול',
    performance: 'ביצועים',
    signin: 'התחברות',
    signup: 'הרשמה',
    signout: 'התנתקות',
    about: 'אודות',
    contact: 'צור קשר',
    admin: 'ניהול',
  },
  home: {
    introTitle: 'שלום וברוכים הבאים ל- testapp!',
    introSubTitle: 'מערכת תרגול ייחודית המאפשרת לך',
    introSubTitle2: 'הכנה מנצחת לבחינה!',
    intro1: 'שלום',
    intro2: 'שמי אליאור שקד',
    intro3: 'אני מזמין אותך לתרגל את יכולותיך בכתיבת קוד באופן',
    intro4: 'יצירתי ועצמאי',
    intro5: 'המערכת שלנו מציעה משוב על טסטים מגוונים לשאלות נפוצות במבחן ומאפשרות לך לאמוד את טיב הקוד שלך תוך עמידה בתנאי השאלות (רקורסיה, זמני ריצה ועוד…)הדרך שלנו ארוכה אבל אנו נעשה אותה ביחד תוך מטרה ברורה - לגרום לך לתכנת בצורה מיטבית',
    intro6: 'ולהצליח בבחינה',
    videoError: 'שגיאה בטעינת הסרטון',
  },
  account: {
    title: 'דף חשבון',
    name: 'שם:',
    email: 'אימייל:',
  },
  contact: {
    questions: 'לשאלות:',
    email: 'אימייל:',
  },
  about: {
    founder1: 'אליאור: ילד טוב ירושלים',
    founder2: 'עמית: ילד רע רחובות',
  },
  admin: {
    title: 'דף ניהול - דף סודי!',
    upload: 'העלה קובץ',
    default: 'לא נבחר קובץ',
    verify: 'אמת והעלה CSV',
    alertNoFile: 'לא נבחר קובץ',
    alertSuccess: 'הקובץ עובד בהצלחה',
    alertPleaseUpload: 'אנא העלה קובץ עם סיומת .csv',
  },
  practice: {
    title: 'קוד פייתון לבדיקה',
    darkmode: 'מצב כהה',
    lightmode: 'מצב בהיר',
    font: 'גודל גופן',
    run: 'הרצה',
    submit: 'הגשה',
    output: 'פלט:',
    questionNotFound: 'השאלה {function_name} לא נמצאה.',
    didYouMean: 'האם התכוונת ל{match}?',
  },
  results: {
    functionName: 'שם הפונקציה',
    timeComplexity: 'סיבוכיות זמן ריצה',
    test: 'מבחן',
    error: 'שגיאה',
    pass: 'עבר',
    fail: 'נכשל',
    noTest: 'אין מבחן',
    noLimit: 'ללא מגבלה',
    input: 'Input:',
    expected: 'Expected Output:',
    actual: 'Actual Output:',
    failedRecursion: 'Code should be recursive',
    failedLoops: 'Question allowed for {n_loops_allowed} loops, but {actual_loops} found',
    failedStructure: 'Bad structure',
  },
  performance: {
    title: 'טבלת הגשות מעודכנת',
  },
  sign: {
    in: 'התחברות',
    up: 'הרשמה',
    email: 'אימייל',
    name: 'שם',
    back: 'חזרה',
    password: 'סיסמה',
    forgotPassword: 'שכחת סיסמה?',
    resetPassword: 'איפוס סיסמה',
    codeWill: 'קוד איפוס יישלח לאימייל שלך:',
    sendCode: 'שלח קוד איפוס',
    defaultCode: 'נא להכניס קוד אישור',
    defaultPassword: 'נא להכניס סיסמה חדשה',
    confirmAccount: 'נא לאשר חשבון',
    confirmationCode: 'קוד אישור',
    confirmAccountNow: 'אישור חשבון',
    resendCode: 'שלח קוד מחדש',
    changeSuccess: 'הסיסמה אופסה בהצלחה!',
  }
};
