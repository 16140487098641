// src/components/SignUp.js
import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { CognitoUser, AuthenticationDetails, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import UserPool from '../cognitoConfig';
import '../css/SignInSignUp.css';
import { useNavigate } from 'react-router-dom';  // Import useNavigate
import config from '../config';
import { sanitizeEmail, sanitizeName } from './securityFunctions';
import I18n from '../i18n';
import { LanguageContext } from './LanguageContext';

const SignUp = ({ onSignIn }) => {
  const { language } = useContext(LanguageContext); // Get the language from context
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [error, setError] = useState(null);
  const [isConfirming, setIsConfirming] = useState(false);
  const [user, setUser] = useState(null);
  const apiUrl = config.API_URL;
  const userPool = config.USER_POOL_ID;

  const navigate = useNavigate();  // Initialize useNavigate inside the component

  // State variables for translated text
  const [signUpText, setSignUpText] = useState(I18n.t('sign.up'));
  const [confirmAccountText, setConfirmAccountText] = useState(I18n.t('sign.confirmAccount'));
  const [nameText, setNameText] = useState(I18n.t('sign.name'));
  const [emailText, setEmailText] = useState(I18n.t('sign.email'));
  const [passwordText, setPasswordText] = useState(I18n.t('sign.password'));
  const [confirmationCodeText, setConfirmationCodeText] = useState(I18n.t('sign.confirmationCode'));
  const [confirmNowText, setConfirmNowText] = useState(I18n.t('sign.confirmAccountNow'));
  const [resendCodeText, setResendCodeText] = useState(I18n.t('sign.resendCode'));

  // Update translations when the language changes
  useEffect(() => {
    setSignUpText(I18n.t('sign.up'));
    setConfirmAccountText(I18n.t('sign.confirmAccount'));
    setNameText(I18n.t('sign.name'));
    setEmailText(I18n.t('sign.email'));
    setPasswordText(I18n.t('sign.password'));
    setConfirmationCodeText(I18n.t('sign.confirmationCode'));
    setConfirmNowText(I18n.t('sign.confirmAccountNow'));
    setResendCodeText(I18n.t('sign.resendCode'));
  }, [language]);

  const handleSignUp = (e) => {
    e.preventDefault();

    const sanitizedName = sanitizeName(name);
    if (typeof sanitizedName === 'string' && sanitizedName.startsWith('Name must be')) {
      alert(sanitizedName);
      return;
    }
    
    const sanitizedEmail = sanitizeEmail(email);
    if (typeof sanitizedEmail === 'string' && (sanitizedEmail.startsWith('Email must be') || sanitizedEmail.startsWith('Invalid email'))) {
      alert(sanitizedEmail);
      return;
    }


    const attributeList = [
      new CognitoUserAttribute({ Name: 'email', Value: sanitizedEmail }),
      new CognitoUserAttribute({ Name: 'name', Value: sanitizedName }),
    ];

    UserPool.signUp(email, password, attributeList, null, (err, result) => {
      if (err) {
        setError(err.message || JSON.stringify(err));
        return;
      }

      console.log('Sign-up successful:', result);
      setUser(new CognitoUser({ Username: sanitizedEmail, Pool: UserPool }));
      setIsConfirming(true);
    });
  };

  const handleConfirm = (e) => {
    e.preventDefault();
  
    const sanitizedEmail = sanitizeEmail(email);
    if (typeof sanitizedEmail === 'string' && (sanitizedEmail.startsWith('Email must be') || sanitizedEmail.startsWith('Invalid email'))) {
      alert(sanitizedEmail);
      return;
    }

    if (!user) {
      setError("No user context available. Please sign up again.");
      return;
    }
  
    user.confirmRegistration(confirmationCode, true, (err, result) => {
      if (err) {
        console.error('Confirmation error:', err);
        setError(err.message || JSON.stringify(err));
        return;
      }
  
      console.log('Confirmation successful:', result);
  
      const authDetails = new AuthenticationDetails({
        Username: sanitizedEmail,
        Password: password,
      });
  
      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          console.log('Sign-in after confirmation successful:', data);
  
          user.getUserAttributes((err, attributes) => {
            if (err) {
              console.error('Error getting user attributes:', err);
            } else {
              const nameAttr = attributes.find(attr => attr.Name === 'name');
              const subAttr = attributes.find(attr => attr.Name === 'sub');
              console.log('nameAttr: ', nameAttr ? nameAttr.Value : null);
              console.log('subAttr: ', subAttr ? subAttr.Value: null);
              const userInfo = {
                uuid: subAttr ? subAttr.Value: null,
                user_pool: userPool,
                is_paying: 0,
                type: '',
                name: nameAttr ? nameAttr.Value : null,
                email: sanitizedEmail,
                institution: '',
                department: '',
                year: 0,
                semester: '',
                moed: ''
              };
  
              // API call to update the AWS DB
              axios.post(`${apiUrl}/users/create_user`, userInfo)
                .then(response => {
                  console.log('User added to database:', response.data);
                  onSignIn(user, userInfo.name);
                  navigate('/');  // Redirect to the home page after successful sign-in
                })
                .catch(error => {
                  console.error('Error adding user to database:', error);
                  setError('Failed to add user to database');
                });
            }
          });
        },
        onFailure: (err) => {
          console.error('Sign-in after confirmation failed:', err);
          setError(err.message || JSON.stringify(err));
        },
      });
    });
  };

  const handleResendCode = () => {
    if (!user) {
      setError("No user context available. Please sign up again.");
      return;
    }

    user.resendConfirmationCode((err, result) => {
      if (err) {
        setError(err.message || JSON.stringify(err));
        return;
      }
      console.log('Confirmation code resent:', result);
      setError('A new confirmation code has been sent to your email.');
    });
  };

  return (
    <div className="auth-container">
      {!isConfirming ? (
        <form className="auth-form" onSubmit={handleSignUp}>
          <h1>{signUpText}</h1>
          <input
            type="text"
            value={name}
            placeholder={nameText}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="email"
            value={email}
            placeholder={emailText}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            value={password}
            placeholder={passwordText}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit">{signUpText}</button>
          {error && <p>{error}</p>}
        </form>
      ) : (
        <form className="auth-form" onSubmit={handleConfirm}>
          <h1>{confirmAccountText}</h1>
          <input
            type="text"
            value={confirmationCode}
            placeholder={confirmationCodeText}
            onChange={(e) => setConfirmationCode(e.target.value)}
          />
          <button type="submit">{confirmNowText}</button>
          <button type="button" onClick={handleResendCode}>{resendCodeText}</button>
          {error && <p>{error}</p>}
        </form>
      )}
    </div>
  );
};

export default SignUp;