import { I18n } from 'i18n-js';

import en from './locales/en';
import he from './locales/he';
import ar from './locales/ar';

const i18n = new I18n({
  en,
  he,
  ar,
});

i18n.enableFallback = true;
i18n.defaultLocale = 'he';

export const setLocale = (locale) => {
  i18n.locale = locale;
};

export default i18n;
