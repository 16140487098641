import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import Papa from 'papaparse';
import I18n from '../i18n';
import '../css/AdminPage.css';
import { LanguageContext } from './LanguageContext';

const AdminPage = ({ userInfo }) => {
  const navigate = useNavigate();
  const apiUrl = config.API_URL;
  const { language } = useContext(LanguageContext); // Get the language from context

  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [progress, setProgress] = useState(0); // State to track the progress
  const [rowsProcessed, setRowsProcessed] = useState(0); // Track rows processed

  const [title, setTitle] = useState(I18n.t('admin.title'));
  const [upload, setUpload] = useState(I18n.t('admin.upload'));
  const [verify, setVerify] = useState(I18n.t('admin.verify'));
  const [alertNoFile, setAlertNoFile] = useState(I18n.t('admin.alertNoFile'));
  const [alertSuccess, setAlertSuccess] = useState(I18n.t('admin.alertSuccess'));
  const [alertPleaseUpload, setAlertPleaseUpload] = useState(I18n.t('admin.alertPleaseUpload'));
  const [defaultText, setDefaultText] = useState(I18n.t('admin.default'));
  const [loading, setLoading] = useState(I18n.t('basic.loading'));

  useEffect(() => {
    setTitle(I18n.t('admin.title'));
    setUpload(I18n.t('admin.upload'));
    setVerify(I18n.t('admin.verify'));
    setAlertNoFile(I18n.t('admin.alertNoFile'));
    setAlertSuccess(I18n.t('admin.alertSuccess'));
    setAlertPleaseUpload(I18n.t('admin.alertPleaseUpload'));
    setDefaultText(I18n.t('admin.default'));
    setLoading(I18n.t('basic.loading'));
  }, [language]); // Rerun whenever the language changes
  
  useEffect(() => {
    if (userInfo !== null) {
      const isAdmin = userInfo && userInfo.type === 'admin';
      if (!isAdmin) {
        navigate('/'); 
      }
    }
  }, [userInfo, navigate]);

  if (userInfo === null) {
    // Optionally render a loading indicator while userInfo is being fetched
    return <div>{loading}</div>;
  }

const upload_test = async (rowData) => {
  console.log('Uploading row:', rowData);
  const versionMatch = rowData[2].match(/^(\d+)([a-zA-Z]*)$/);
  const version_int = versionMatch ? parseInt(versionMatch[1], 10) : 0;
  const version_str = versionMatch ? versionMatch[2] : '';

  const questionInfo = {
    name: rowData[0],	
    text: rowData[1],
    version_int: version_int,
    version_str: version_str,
    difficulty: parseInt(rowData[3], 10),
    time_complexity: rowData[4],
    is_recursive: parseInt(rowData[5], 10),
    max_loop_count: parseInt(rowData[6], 10)
  };
  console.log('questionInfo: ', questionInfo)
  const testInfo = {
    test_name: rowData[7] == 'time_complexity' ? rowData[7] : `test_${rowData[7]}`,
    input: rowData[8],
    expected: rowData[9],
    allow_each_expected: parseInt(rowData[10], 10)
  };
  console.log('testInfo: ', testInfo)
  let question_id;
  try {
    let getQuestionResponse = await axios.get(`${apiUrl}/questions/get_question/${questionInfo.name}`);
    if (!getQuestionResponse.data.question_id) {
      const postQuestionResponse = await axios.post(`${apiUrl}/questions/add_question`, questionInfo);
      question_id = postQuestionResponse.data.question_id;
      console.log('post question_id:', postQuestionResponse.data.question_id);
      console.log('post question_id2:', question_id);
    }
    else {
      question_id = getQuestionResponse.data.question_id;
      console.log('get question_id:', getQuestionResponse.data.question_id);
      console.log('get question_id2:', question_id);
    }

    // const questionId = getQuestionResponse.data.question_id;

    let getTestResponse = await axios.get(`${apiUrl}/tests/get_test/${question_id}/${testInfo.test_name}`);
    if (!getTestResponse.data.test_id) {
      testInfo['question_id'] = question_id;
      console.log('testInfo2: ', testInfo)
      const postTestResponse = await axios.post(`${apiUrl}/tests/add_test`, testInfo);
      console.log('Test added to database:', getTestResponse.data);
    }
  } catch (error) {
    console.error('Error processing the row:', error);
  }
};

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      setFileName(file.name);
    }
  };

  const handleVerifyAndUpload = async () => {
    if (!selectedFile) {
      alert(alertNoFile);
      return;
    }

    if (selectedFile.name.endsWith('.csv')) {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const text = event.target.result;
        console.log('text: ', text)
        const rows = Papa.parse(text, { skipEmptyLines: true }).data.slice(1); // Skip the header row
        // const rows = rows_raw.split('\r').slice(1)
        console.log('rows: ', rows)
        const totalRows = rows.length;
        setRowsProcessed(0);
        setProgress(0);

        for (let i = 0; i < rows.length; i++) {
          const row = rows[i];
          if (row[0]) {
            await upload_test(row);
            // Update progress
            setRowsProcessed(i + 1);
            setProgress(Math.round(((i + 1) / totalRows) * 100));
          }
        }
        alert(alertSuccess);
      };
      reader.readAsText(selectedFile);
    } else {
      alert(alertPleaseUpload);
    }
  };

  return (
    <div>
      <h2>{title}</h2>
      
      <button onClick={() => document.getElementById('fileInput').click()}>
      {upload}
      </button>
      <input
        type="file"
        id="fileInput"
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
      <input
        type="text"
        value={fileName}
        readOnly
        placeholder={defaultText}
        style={{ marginLeft: '10px' }}
      />

      <br /><br />
      
      <button onClick={handleVerifyAndUpload}>
      {verify}
      </button>
      {/* Progress bar */}
      {progress > 0 && (
        <div className="progress-container">
          <div
            className="progress-bar"
            style={{ width: `${progress}%` }}
          ></div>
          <div className="progress-percentage">{`${progress}%`}</div>
          <p className="rows-processed-text">{`Rows processed: ${rowsProcessed}`}</p>
        </div>
)}
    </div>
  );
};

export default AdminPage;