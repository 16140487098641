import React, { useContext, useEffect, useState } from 'react';
import '../css/AccountPage.css'; // Import the CSS file
import I18n from '../i18n';
import { LanguageContext } from './LanguageContext';

const AccountPage = ({ userInfo, name }) => {
  const [loading, setLoading] = useState(true); // State to manage loading
  const { language } = useContext(LanguageContext); // Get the language from context
  const [title, setTitle] = useState(I18n.t('account.title'));
  const [loadingTxt, setLoadingTxt] = useState(I18n.t('basic.loading'));
  const [nameTxt, setNameTxt] = useState(I18n.t('account.name'));
  const [email, setEmail] = useState(I18n.t('account.email'));

  useEffect(() => {
    setTitle(I18n.t('account.title'));
    setLoadingTxt(I18n.t('basic.loading'));
    setNameTxt(I18n.t('account.name'));
    setEmail(I18n.t('account.email'));
  }, [language]); // Rerun whenever the language changes


  useEffect(() => {
    // Check if userInfo is available
    if (userInfo) {
      setLoading(false); // Stop loading when userInfo is available
    }
  }, [userInfo]);

  if (loading) {
    // Display loading message while waiting for userInfo
    return (
      <div className="container">
        <h2>{title}</h2>
        <p>{loadingTxt}</p>
      </div>
    );
  }

  return (
    <div className="account-container">
      <h2 className="account-page">{title}</h2>
      <ul>
        <li>{nameTxt} {name}</li>
        <li>{email} {userInfo.email}</li>
      </ul>
    </div>
  );
};

export default AccountPage;
