import React, { useContext, useEffect, useState } from 'react';
import { CognitoUser } from 'amazon-cognito-identity-js';
import UserPool from '../cognitoConfig';
import I18n from '../i18n';
import { LanguageContext } from './LanguageContext';

const ResetPassword = ({ email, onComplete }) => {
  const [confirmationCode, setConfirmationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [step, setStep] = useState(1);  // Step 1: Send code, Step 2: Confirm new password
  const { language } = useContext(LanguageContext); // Get the language from context

  // State variables for translated text
  const [resetCodeSentText, setResetCodeSentText] = useState(I18n.t('sign.codeWill'));
  const [sendResetCodeText, setSendResetCodeText] = useState(I18n.t('sign.sendCode'));
  const [enterCodeText, setEnterCodeText] = useState(I18n.t('sign.defaultCode'));
  const [enterNewPasswordText, setEnterNewPasswordText] = useState(I18n.t('sign.defaultPassword'));
  const [resetPasswordText, setResetPasswordText] = useState(I18n.t('sign.resetPassword'));
  const [changeSuccessText, setChangeSuccessText] = useState(I18n.t('sign.changeSuccess'));

  // Update translations when the language changes
  useEffect(() => {
    setResetCodeSentText(I18n.t('sign.codeWill'));
    setSendResetCodeText(I18n.t('sign.sendCode'));
    setEnterCodeText(I18n.t('sign.defaultCode'));
    setEnterNewPasswordText(I18n.t('sign.defaultPassword'));
    setResetPasswordText(I18n.t('sign.resetPassword'));
    setChangeSuccessText(I18n.t('sign.changeSuccess'));
  }, [language]);

  // Function to send the reset code
  const handleForgotPassword = () => {
    const userData = {
      Username: email,
      Pool: UserPool,
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.forgotPassword({
      onSuccess: (data) => {
        console.log('Password reset code sent:', data);
        setStep(2); // Move to the next step: entering confirmation code and new password
      },
      onFailure: (err) => {
        console.error('Error sending reset code:', err.message || JSON.stringify(err));
        setErrorMessage(err.message || 'An error occurred.');
      },
    });
  };

  // Function to confirm the new password
  const handleConfirmPassword = () => {
    const userData = {
      Username: email,
      Pool: UserPool,
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmPassword(confirmationCode, newPassword, {
      onSuccess: () => {
        alert(changeSuccessText);
        onComplete(); // Notify parent component that reset is complete
      },
      onFailure: (err) => {
        console.error('Error confirming new password:', err.message || JSON.stringify(err));
        setErrorMessage(err.message || 'An error occurred.');
      },
    });
  };

  return (
    <div className="reset-password-form">
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      
      {step === 1 ? (
        <>
          <p>{resetCodeSentText} {email}</p>
          <button onClick={handleForgotPassword}>{sendResetCodeText}</button>
        </>
      ) : (
        <>
          <input
            type="text"
            placeholder={enterCodeText}
            value={confirmationCode}
            onChange={(e) => setConfirmationCode(e.target.value)}
          />
          <input
            type="password"
            placeholder={enterNewPasswordText}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <button onClick={handleConfirmPassword}>{resetPasswordText}</button>
        </>
      )}
    </div>
  );
};

export default ResetPassword;
