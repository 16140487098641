import React, { useContext, useEffect, useState } from 'react';
import pythonLogo from '../assets/founder1_pic.jpg'; // Correctly import the image
import '../css/AboutUsPage.css'; // Import the CSS file
import I18n from '../i18n';
import { LanguageContext } from './LanguageContext';

const AboutUsPage = ({ uuid }) => {
  const { language } = useContext(LanguageContext); // Get the language from context
  const [founder1, setFounder1] = useState(I18n.t('about.founder1'));
  const [founder2, setFounder2] = useState(I18n.t('about.founder2'));
  const [intro1, setIntro1] = useState(I18n.t('home.intro1'));
  const [intro2, setIntro2] = useState(I18n.t('home.intro2'));
  const [intro3, setIntro3] = useState(I18n.t('home.intro3'));
  const [intro4, setIntro4] = useState(I18n.t('home.intro4'));
  const [intro5, setIntro5] = useState(I18n.t('home.intro5'));
  const [intro6, setIntro6] = useState(I18n.t('home.intro6'));

  useEffect(() => {
    setFounder1(I18n.t('about.founder1'));
    setFounder2(I18n.t('about.founder2'));
    setIntro1(I18n.t('home.intro1'));
    setIntro2(I18n.t('home.intro2'));
    setIntro3(I18n.t('home.intro3'));
    setIntro4(I18n.t('home.intro4'));
    setIntro5(I18n.t('home.intro5'));
    setIntro6(I18n.t('home.intro6'));
  }, [language]); // Rerun whenever the language changes

  return (
    <div className="about-container">
      <img
        src={pythonLogo}
        alt="About Us"
        className="about-image"
      />
      <div className="about-text-container">
      {/* <p className="about-text">{founder1}</p>
      <p className="about-text">{founder2}</p> */}
      <h2>{intro1}</h2>
      <h2 className="intro-bold">{intro2}</h2>
      <h3>{intro3} <span className="intro-light-green">{intro4}</span></h3>
      <h3>{intro5} <span className="intro-light-purple">{intro6}</span></h3>
      </div>
    </div>
  );
};

export default AboutUsPage;
