export default {
  basic: {
    loading: 'جارٍ التحميل...',
    selectLanguage: 'اختر اللغة',
  },
  navbar: {
    home: 'الصفحة الرئيسية',
    members: 'منطقة الأعضاء',
    account: 'الحساب',
    practice: 'نظام التدريب',
    performance: 'الأداء',
    signin: 'تسجيل الدخول',
    signup: 'إنشاء حساب',
    signout: 'تسجيل الخروج',
    about: 'معلومات عنا',
    contact: 'اتصل بنا',
    admin: 'المشرف',
  },
  home: {
    introTitle: 'مرحباً بكم في testapp!',
    introSubTitle: 'نظام تدريب فريد يتيح لك التحضير للامتحان مع',
    introSubTitle2: 'نجاح مضمون!',
    intro1: 'مرحباً',
    intro2: 'اسمي إليور شاكيد',
    intro3: 'أدعوكم لممارسة مهاراتكم في البرمجة بطريقة',
    intro4: 'إبداعية ومستقلة',
    intro5: 'نظامنا يوفر لك ملاحظات حول اختبارات متعددة لأسئلة شائعة في الامتحان، مما يتيح لك تقييم جودة الكود الخاص بك مع الالتزام بشروط السؤال (التكرار، وقت التشغيل، وغيرها...). رحلتنا طويلة، ولكننا سنسيرها معاً بهدف واضح - مساعدتك على كتابة الكود بشكل مثالي',
    intro6: 'والنجاح في الامتحان',
    videoError: 'خطأ في تحميل الفيديو',
  },
  account: {
    title: 'صفحة الحساب',
    name: 'الاسم:',
    email: 'البريد الإلكتروني:',
  },
  contact: {
    questions: 'لأي استفسارات:',
    email: 'البريد الإلكتروني:',
  },
  about: {
    founder1: 'إليور: ولد جيد',
    founder2: 'عميت: ولد سيء',
  },
  admin: {
    title: 'صفحة المشرف - صفحة سرية!',
    upload: 'رفع ملف',
    default: 'لم يتم اختيار ملف',
    verify: 'تحقق وارفع ملف CSV',
    alertNoFile: 'لم يتم اختيار ملف',
    alertSuccess: 'تمت معالجة الملف بنجاح',
    alertPleaseUpload: 'يرجى رفع ملف بامتداد .csv',
  },
  practice: {
    title: 'مدقق كود Python',
    darkmode: 'الوضع الليلي',
    lightmode: 'الوضع الفاتح',
    font: 'حجم الخط',
    run: 'تشغيل',
    submit: 'إرسال',
    output: 'الناتج:',
    questionNotFound: 'السؤال {function_name} غير موجود.',
    didYouMean: 'هل تقصد {match}؟'
  },
  results: {
    functionName: 'اسم الوظيفة',
    timeComplexity: 'تعقيد الوقت',
    test: 'الاختبار',
    error: 'خطأ',
    pass: 'ناجح',
    fail: 'فشل',
    noTest: 'لا يوجد اختبار',
    noLimit: 'بدون حد',
    input: 'Input:',
    expected: 'Expected Output:',
    actual: 'Actual Output:',
    failedRecursion: 'Code should be recursive',
    failedLoops: 'Question allowed for {n_loops_allowed} loops, but {actual_loops} found',
    failedStructure: 'Bad structure',
  },
  performance: {
    title: 'جدول التقديمات المحدثة',
  },
  sign: {
    in: 'تسجيل الدخول',
    up: 'إنشاء حساب',
    email: 'البريد الإلكتروني',
    name: 'الاسم',
    back: 'رجوع',
    password: 'كلمة المرور',
    forgotPassword: 'هل نسيت كلمة المرور؟',
    resetPassword: 'إعادة تعيين كلمة المرور',
    codeWill: 'سيتم إرسال رمز إعادة التعيين إلى بريدك الإلكتروني:',
    sendCode: 'إرسال رمز إعادة التعيين',
    defaultCode: 'أدخل رمز التأكيد',
    defaultPassword: 'أدخل كلمة المرور الجديدة',
    confirmAccount: 'تأكيد حسابك',
    confirmationCode: 'رمز التأكيد',
    confirmAccountNow: 'تأكيد الحساب',
    resendCode: 'إعادة إرسال الرمز',
    changeSuccess: 'تمت إعادة تعيين كلمة المرور بنجاح!',
  }
};
