import React, { useState, useContext, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../css/NavBar.css';
import I18n from '../i18n';
import { LanguageContext } from './LanguageContext';

const Navbar = ({ user, name, handleSignOut, userInfo }) => {
  const navigate = useNavigate();
  const { language, handleLanguageChange } = useContext(LanguageContext);
  const [homeText, setHomeText] = useState(I18n.t('navbar.home'));
  const [membersText, setMembersText] = useState(I18n.t('navbar.members'));
  const [accountText, setAccountText] = useState(I18n.t('navbar.account'));
  const [practiceText, setPracticeText] = useState(I18n.t('navbar.practice'));
  const [performanceText, setPerformanceText] = useState(I18n.t('navbar.performance'));
  const [signinText, setSigninText] = useState(I18n.t('navbar.signin'));
  const [signupText, setSignupText] = useState(I18n.t('navbar.signup'));
  const [signoutText, setSignoutText] = useState(I18n.t('navbar.signout'));
  const [aboutText, setAboutText] = useState(I18n.t('navbar.about'));
  const [contactText, setContactText] = useState(I18n.t('navbar.contact'));
  const [adminText, setAdminText] = useState(I18n.t('navbar.admin'));

  useEffect(() => {
    setHomeText(I18n.t('navbar.home'));
    setMembersText(I18n.t('navbar.members'));
    setAccountText(I18n.t('navbar.account'));
    setPracticeText(I18n.t('navbar.practice'));
    setPerformanceText(I18n.t('navbar.performance'));
    setSigninText(I18n.t('navbar.signin'));
    setSignupText(I18n.t('navbar.signup'));
    setSignoutText(I18n.t('navbar.signout'));
    setAboutText(I18n.t('navbar.about'));
    setContactText(I18n.t('navbar.contact'));
    setAdminText(I18n.t('navbar.admin'));
  }, [language]);

  const onSignOut = () => {
    handleSignOut();
    navigate('/');
  };

  const isPayingUser = userInfo && userInfo.is_paying;
  const isAdmin = userInfo && userInfo.type === 'admin';

  return (
    <nav className="navbar">
      <ul>
        <li>
          <a href="/">{homeText}</a>
        </li>
        <div className="dropdown">
          <button className="dropbtn">
            {membersText}
            <i className="fa fa-caret-down"></i>
          </button>
          <div className="dropdown-content">
            {user ? (
              <>
                <a href="/account">{accountText}</a>
                <a href="/tests">{practiceText}</a>
                <a href="/performance">{performanceText}</a>
              </>
            ) : (
              <>
                <a href="/signin">{signinText}</a>
                <a href="/signup">{signupText}</a>
              </>
            )}
          </div>
        </div>
        <li>
          <a href="/about-us">{aboutText}</a>
        </li>
        <li>
          <a href="/contact">{contactText}</a>
        </li>
        {isAdmin && (
          <li>
            <a href="/admin">{adminText}</a>
          </li>
        )}
      </ul>

      <div className="language-switcher">
        <select id="language-select" value={language} onChange={handleLanguageChange}>
          <option value="en">English</option>
          <option value="he">עברית</option>
          <option value="ar">العربية</option>
        </select>
      </div>

      {user && (
        <div className="signout-container">
          <button onClick={onSignOut}>{signoutText}</button>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
