export default {
  basic: {
    loading: 'Loading...',
    selectLanguage: 'Select Language',
  },
  navbar: {
    home: 'Home',
    members: 'Members Area',
    account: 'Account',
    practice: 'Practice System',
    performance: 'Performance',
    signin: 'Sign In',
    signup: 'Sign Up',
    signout: 'Sign Out',
    about: 'About Us',
    contact: 'Contact',
    admin: 'Admin',
  },
  home: {
    introTitle: 'Hello and welcome to testapp!',
    introSubTitle: 'A unique practice system that allows you to prepare for the exam with',
    introSubTitle2: 'winning success!',
    intro1: 'Hello',
    intro2: 'My name is Elior Shaked',
    intro3: 'I invite you to practice your coding skills in a',
    intro4: 'creative and independent way',
    intro5: 'Our system provides feedback on various tests for common exam questions, allowing you to assess the quality of your code while meeting the question requirements (recursion, runtime, etc...). Our journey is long, but we will make it together with a clear goal - to help you code optimally',
    intro6: 'and succeed in the exam',
    videoError: 'Error loading the video',
  },
  account: {
    title: 'Account Page',
    name: 'Name:',
    email: 'Email:',
  },
  contact: {
    questions: 'For any questions:',
    email: 'Email:',
  },
  about: {
    founder1: 'Elior: A good boy',
    founder2: 'Amit: A bad boy',
  },
  admin: {
    title: 'Admin Page - SECRET PAGE!',
    upload: 'Upload File',
    default: 'No file selected',
    verify: 'Verify and Upload CSV',
    alertNoFile: 'No file selected',
    alertSuccess: 'File processed successfully',
    alertPleaseUpload: 'Please upload a file with a .csv extension',
  },
  practice: {
    title: 'Python Code Checker',
    darkmode: 'Dark Mode',
    lightmode: 'Light Mode',
    font: 'Font Size',
    run: 'Run',
    submit: 'Submit',
    output: 'Output:',
    questionNotFound: 'Question {function_name} was not found.',
    didYouMean: 'Did you mean {match}?',
  },
  results: {
    functionName: 'Function Name',
    timeComplexity: 'Time Complexity',
    test: 'Test',
    error: 'Error',
    pass: 'Passed',
    fail: 'Failed',
    noTest: 'No Test',
    noLimit: 'No limit',
    input: 'Input:',
    expected: 'Expected Output:',
    actual: 'Actual Output:',
    failedRecursion: 'Code should be recursive',
    failedLoops: 'Question allowed {n_loops_allowed} loops, but {actual_loops} found',
    failedStructure: 'Bad structure',
  }, 
  performance: {
    title: ' Updated Submissions Table',
  },
  sign: {
    in: 'Sign In',
    up: 'Sign Up',
    email: 'Email',
    name: 'Name',
    back: 'Back',
    password: 'Password',
    forgotPassword: 'Forgot Password?',
    resetPassword: 'Reset Password',
    codeWill: 'A reset code will be sent to your email:',
    sendCode: 'Send Reset Code',
    defaultCode: 'Enter confirmation code',
    defaultPassword: 'Enter new password',
    confirmAccount: 'Confirm Your Account',
    confirmationCode: 'Confirmation Code',
    confirmAccountNow: 'Confirm Account',
    resendCode: 'Resend Code',
    changeSuccess: 'Password has been reset successfully!',
  } 
  };