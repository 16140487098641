// src/cognitoConfig.js
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import config from './config'

const poolData = {
  UserPoolId: config.USER_POOL_ID,
  ClientId: config.APP_CLIENT_ID
  // UserPoolId: process.env.USER_POOL_ID,
  // ClientId: process.env.APP_CLIENT_ID
};

export default new CognitoUserPool(poolData);