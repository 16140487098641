import React, { useContext, useEffect, useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism'; // Using darcula theme
import '../css/Home.css'; // Import your CSS file
import backgroundVideoFile from '../assets/background_stargate.mp4'; // Adjust the path based on your folder structure
import I18n from '../i18n';
import { LanguageContext } from './LanguageContext';

const codeExamples = [
  `def fibonacci(n):
    series = [0, 1]
    while len(series) < n:
        series.append(series[-1] + series[-2])
    return series`,

  `def factorial(n):
    if n == 0 or n == 1:
        return 1
    else:
        return n * factorial(n - 1)`,

  `def is_prime(num):
    if num < 2:
        return False
    for i in range(2, int(num ** 0.5) + 1):
        if num % i == 0:
            return False
    return True`,

  `def reverse_string(s):
    return s[::-1]`,

  `def sum_list(numbers):
    total = 0
    for num in numbers:
        total += num
    return total`,
];

const Home = () => {
  const [currentExample, setCurrentExample] = useState(0);
  const { language } = useContext(LanguageContext); // Get the language from context
  const [introTitle, setIntroTitle] = useState(I18n.t('home.introTitle'));
  const [introSubTitle, setIntroSubTitle] = useState(I18n.t('home.introSubTitle'));
  const [introSubTitle2, setIntroSubTitle2] = useState(I18n.t('home.introSubTitle2'));

  const [videoError, setVideoError] = useState(I18n.t('home.videoError'));

  useEffect(() => {
    setIntroTitle(I18n.t('home.introTitle'));
    setIntroSubTitle(I18n.t('home.introSubTitle'));
    setIntroSubTitle2(I18n.t('home.introSubTitle2'));

    setVideoError(I18n.t('home.videoError'));
  }, [language]); // Rerun whenever the language changes


  // Cycle through the code examples every 10 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentExample((prevExample) => (prevExample + 1) % codeExamples.length);
    }, 10000);

    return () => clearInterval(interval); // Clear interval on component unmount
  }, []);

  return (
<section className="homepage-container">
  {/* Background Video */}
  <div className="background-video-wrapper">
    <video autoPlay muted loop className="background-video">
      <source src={backgroundVideoFile} type="video/mp4" />
      {videoError}
    </video>
    <div className="video-overlay"></div> {/* Dark overlay */}
  </div>

  {/* Content Area */}
  <div className="content-area">
    <div className="content-wrapper">
      <div className="hero-text">
        <h1>{introTitle}</h1>
        <p>{introSubTitle} <span className="intro-light-purple">{introSubTitle2}</span></p>
      </div>

      <div className="hero-code">
        <div className="window-controls">
          <span className="red-dot"></span>
          <span className="yellow-dot"></span>
          <span className="green-dot"></span>
        </div>
        <SyntaxHighlighter language="python" style={darcula}>
          {codeExamples[currentExample]}
        </SyntaxHighlighter>
      </div>
    </div>

  </div>
</section>
  );
};

export default Home;
