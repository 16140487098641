import React, { useContext, useEffect, useState } from 'react';
import '../css/ContactPage.css'; // Import the CSS file
import I18n from '../i18n';
import { LanguageContext } from './LanguageContext';

const ContactPage = ({ uuid }) => {
  const { language } = useContext(LanguageContext); // Get the language from context

  const [questions, setQuestions] = useState(I18n.t('contact.questions'));
  const [email, setEmail] = useState(I18n.t('contact.email'));

  useEffect(() => {
    setQuestions(I18n.t('contact.questions'));
    setEmail(I18n.t('account.email'));
  }, [language]); // Rerun whenever the language changes

  return (
    <div className="contact-container">
      <p>{questions}</p>
      <p>
      {email} <a href="mailto:eliorshaked.python@gmail.com" className="email-link">eliorshaked.python@gmail.com</a>
      </p>
    </div>
  );
};

export default ContactPage;