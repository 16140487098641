import SecurityConstants from '../constants/SecurityConstants';

// Sanitize Name
export function sanitizeName(name) {
    const sanitized = name.replace(/[^a-zA-Z\s]/g, '').trim();
    if (sanitized.length > SecurityConstants.SQL_USER_NAME_STRING_LIMIT) {
        return `Name must be ${SecurityConstants.SQL_USER_NAME_STRING_LIMIT} characters or less`;
    }
    return sanitized;
    }
    
// Sanitize Email
export function sanitizeEmail(email) {
    const sanitizedEmail = email.trim().toLowerCase();
    if (sanitizedEmail.length > SecurityConstants.SQL_USER_EMAIL_STRING_LIMIT) {
        return `Email must be ${SecurityConstants.SQL_USER_EMAIL_STRING_LIMIT} characters or less`
    }
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(sanitizedEmail)) {
        return "Invalid email address"
    }
    return sanitizedEmail;
    }

export function sanitizeConfirmationCode(code) {
    const sanitized = code.replace(/[^0-9]/g, '').trim();
    if (sanitized.length == SecurityConstants.SQL_CONFIRMATION_CODE_STRING_LIMIT) {
        return `Confirmation code must be ${SecurityConstants.SQL_CONFIRMATION_CODE_STRING_LIMIT} digits`;
    }
    return sanitized;
    }

export function sanitizePythonCode(code) {
    // const sanitizedCode = code.replace(/</g, '&lt;').replace(/>/g, '&gt;');
    if (code.length > SecurityConstants.SQL_CODE_STRING_LIMIT) {
        return `Code must be ${SecurityConstants.SQL_CODE_STRING_LIMIT} characters or less`;
    }
    return code;
}