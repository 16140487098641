import React, { useState, useContext, useEffect } from 'react';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import UserPool from '../cognitoConfig';
import '../css/SignInSignUp.css';  // Correct CSS import path
import { useNavigate } from 'react-router-dom';  // Import useNavigate
import ResetPassword from './ResetPassword'; // Import the ResetPassword component
import { sanitizeEmail } from './securityFunctions';
import { LanguageContext } from './LanguageContext'; // Import LanguageContext
import I18n from '../i18n';

const SignIn = ({ onSignIn }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [showResetPassword, setShowResetPassword] = useState(false);  // State to toggle reset password view
  const { language } = useContext(LanguageContext); // Get the language from context

  const navigate = useNavigate();  // Initialize navigate inside the component

   // State variables for translated text
   const [signInText, setSignInText] = useState(I18n.t('sign.in'));
   const [resetPasswordText, setResetPasswordText] = useState(I18n.t('sign.resetPassword'));
   const [emailText, setEmailText] = useState(I18n.t('sign.email'));
   const [passwordText, setPasswordText] = useState(I18n.t('sign.password'));
   const [forgotPasswordText, setForgotPasswordText] = useState(I18n.t('sign.forgotPassword'));
   const [backText, setBackText] = useState(I18n.t('sign.back'));
 
   // Update translations when the language changes
   useEffect(() => {
     setSignInText(I18n.t('sign.in'));
     setResetPasswordText(I18n.t('sign.resetPassword'));
     setEmailText(I18n.t('sign.email'));
     setPasswordText(I18n.t('sign.password'));
     setForgotPasswordText(I18n.t('sign.forgotPassword'));
     setBackText(I18n.t('sign.back'));
   }, [language]);

  const handleSignIn = (e) => {
    e.preventDefault();

    const sanitizedEmail = sanitizeEmail(email);
    if (typeof sanitizedEmail === 'string' && (sanitizedEmail.startsWith('Email must be') || sanitizedEmail.startsWith('Invalid email'))) {
      alert(sanitizedEmail);
      return;
    }
    const user = new CognitoUser({
      Username: sanitizedEmail,
      Pool: UserPool,
    });

    const authDetails = new AuthenticationDetails({
      Username: sanitizedEmail,
      Password: password,
    });

    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        console.log('Sign-in successful:', data);

        user.getUserAttributes((err, attributes) => {
          if (err) {
            console.error('Error getting user attributes:', err);
          } else {
            const nameAttr = attributes.find(attr => attr.Name === 'name');
            onSignIn(user, nameAttr ? nameAttr.Value : null);
            navigate('/');  // Redirect to the home page after successful sign-in
          }
        });
      },

      onFailure: (err) => {
        console.error('Sign-in failed:', err);
        setError(err.message || JSON.stringify(err));
      },
    });
  };

  const handleForgotPasswordClick = () => {
    if (!email) {
      setError('Please enter your email to reset your password.');
      return;
    }
    setShowResetPassword(true);  // Show reset password fields
    setError(null);  // Clear any error messages
  };

  const handleResetComplete = () => {
    // Logic after password reset is complete
    setShowResetPassword(false);  // Return to sign-in state
    setEmail('');  // Clear email field if needed
    setPassword('');  // Clear password field
    setError(null);  // Clear any error messages
  };

  const handleBackToSignIn = () => {
    setShowResetPassword(false);  // Return to sign-in state
    setError(null);  // Clear any error messages
  };

  return (
    <div className="auth-container">
      <div className="auth-form">
        <h1>{showResetPassword ? resetPasswordText : signInText}</h1>
        {error && <p className="error-message">{error}</p>}

        {!showResetPassword ? (
          <>
            <form onSubmit={handleSignIn}>
              <input
                type="email"
                value={email}
                placeholder={emailText}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                type="password"
                value={password}
                placeholder={passwordText}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button type="submit">{signInText}</button>
            </form>
            <button onClick={handleForgotPasswordClick} style={{ marginTop: '10px', backgroundColor: '#6c757d' }}>
            {forgotPasswordText}
            </button>
          </>
        ) : (
          <>
            <ResetPassword email={email} onComplete={handleResetComplete} /> {/* Use ResetPassword component */}
            <button onClick={handleBackToSignIn} style={{ marginTop: '10px' }}>{backText}</button>
          </>
        )}
      </div>
    </div>
  );
};

export default SignIn;
