import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import config from '../config';
import '../css/CompilerPage.css'; // Create and import a CSS file for custom styles
import MonacoEditor from '@monaco-editor/react';
import ResultsTable from './ResultsTable';
import { sanitizePythonCode } from './securityFunctions';
import I18n from '../i18n';
import { LanguageContext } from './LanguageContext';

const CompilerPage = ({uuid}) => {
    const { language } = useContext(LanguageContext); // Get the language from context
    const [code, setCode] = useState('');
    const [output, setOutput] = useState('');
    const [results, setResults] = useState('');
    const [uselessResults, setUselessResults] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false); // State to track if submitting
    const [isRunning, setIsRunning] = useState(false); // State to track if submitting
    const [fontSize, setFontSize] = useState(14); 
    const [isOutputVisible, setIsOutputVisible] = useState(false); // State for visibility of output box
    const apiUrl = config.API_URL;
    const [theme, setTheme] = useState('vs-light');
    
    const LOCAL_STORAGE_KEY = 'compiler_code';

    const [outputTxt, setOutputTxt] = useState(I18n.t('practice.output'));
    const [darkmode, setDarkmode] = useState(I18n.t('practice.darkmode'));
    const [lightmode, setLightmode] = useState(I18n.t('practice.lightmode'));
    const [font, setFont] = useState(I18n.t('practice.font'));
    const [title, setTitle] = useState(I18n.t('practice.title'));
    const [submit, setSubmit] = useState(I18n.t('practice.submit'));
    const [run, setRun] = useState(I18n.t('practice.run'));
    const [questionNotFound, setQuestionNotFound] = useState(I18n.t('practice.questionNotFound'));
    const [didYouMean, setDidYouMean] = useState(I18n.t('practice.didYouMean'));

    useEffect(() => {
      setOutputTxt(I18n.t('practice.output'));
      setDarkmode(I18n.t('practice.darkmode'));
      setLightmode(I18n.t('practice.lightmode'));
      setFont(I18n.t('practice.font'));
      setTitle(I18n.t('practice.title'));
      setSubmit(I18n.t('practice.submit'));
      setRun(I18n.t('practice.run'));
      setQuestionNotFound(I18n.t('practice.questionNotFound'));
      setDidYouMean(I18n.t('practice.didYouMean'));
    }, [language]); // Rerun whenever the language changes

    useEffect(() => {
      const savedCode = localStorage.getItem(LOCAL_STORAGE_KEY);
      if (savedCode) {
          setCode(savedCode); // Set the saved code as the editor's initial value
      }
  }, []);

    const handleEditorChange = (value, event) => {
        setCode(value);
        localStorage.setItem(LOCAL_STORAGE_KEY, value);
      };

    const handleFontSizeChange = (event) => {
        setFontSize(parseInt(event.target.value, 10));
      };
    
    const handleCloseOutput = () => {
        setIsOutputVisible(false); // Hide the output box
    };


    const handleRun = async () => {
      console.log("Submitted code:", code);
      const sanitizedCode = sanitizePythonCode(code);
      
      if (typeof sanitizedCode === 'string' && sanitizedCode.startsWith('Code must be')) {
          alert(sanitizedCode);
          return;
      }
      setIsRunning(true);
      axios.post(`${apiUrl}/code/run`, { user_uuid: uuid, code: sanitizedCode })
          .then(response => {
              console.log('Code output:', response.data);
              setOutput(response.data.output);
              setIsOutputVisible(true);
          })
          .catch(error => {
              console.error('Error evaluating code:', error);
          }).finally(() => {
            setIsRunning(false); // Re-enable button after submission
          });
    };


    const handleSubmit = () => {
        console.log("Submitted code:", code);
        const sanitizedCode = sanitizePythonCode(code);
        
        if (typeof sanitizedCode === 'string' && sanitizedCode.startsWith('Code must be')) {
            alert(sanitizedCode);
            return;
        }
        setIsSubmitting(true);
        axios.post(`${apiUrl}/code/submit`, {user_uuid: uuid, code: sanitizedCode})
        .then(response => {
          console.log('Test results:', response.data);
          console.log('Test results_per_function:', response.data.results_per_function);
          console.log('Test useless_functions:', response.data.useless_functions);
          setResults(response.data.results_per_function);
          setUselessResults(response.data.useless_functions);
        })
        .catch(error => {
          console.error('Error testing code:', error);
        }).finally(() => {
          setIsSubmitting(false); // Re-enable button after submission
        });;
    };

    const renderOutputBox = () => {
      console.log('output: ', output)
      if (!output) return null;

      if (!isOutputVisible) return null;
    
      return (
        <div className="output-box-container">
          <div className={`output-box-title ${document.body.dir}`}>
            <h3>{outputTxt}</h3>
            <button className="close-output-btn" onClick={handleCloseOutput}>✖</button>
          </div>
          <div className="output-box-code">
            <pre>{output}</pre>
          </div>
        </div>
      );
    };
    

    const renderTranslatedMessage = (message, variables) => {
      const regex = /{(\w+)}/g;
      const parts = [];
      let lastIndex = 0;
    
      let match;
      while ((match = regex.exec(message)) !== null) {
        const index = match.index;
        const variableName = match[1];
    
        // Add text before the placeholder
        if (index > lastIndex) {
          parts.push(message.substring(lastIndex, index));
        }
    
        // Add the React component (e.g., <strong>)
        if (variables[variableName]) {
          parts.push(
            <strong key={index}>{variables[variableName]}</strong>
          );
        } else {
          // If variable not found, you can decide how to handle it
          parts.push('');
        }
    
        lastIndex = regex.lastIndex;
      }
    
      // Add any remaining text after the last placeholder
      if (lastIndex < message.length) {
        parts.push(message.substring(lastIndex));
      }
    
      return parts;
    };

const renderResultsTable = () => {
  console.log('results: ', results);
  return (
    <div className="results-table-container">
      <ResultsTable results={results} />
      {uselessResults && uselessResults.length > 0 && (
        <div className="useless-functions-container">
          {uselessResults.map((item, index) => (
            <p className="useless-functions-text" key={index}>
              {item.match === null ? (
                renderTranslatedMessage(questionNotFound, {
                  function_name: item.function_name,
                })
              ) : (
                <>
                  {renderTranslatedMessage(questionNotFound, {
                    function_name: item.function_name,
                  })}{' '}
                  {renderTranslatedMessage(didYouMean, {
                    match: item.match,
                  })}
                </>
              )}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

    
    
    
    const toggleTheme = () => {
        setTheme((prevTheme) => (prevTheme === 'vs-dark' ? 'vs' : 'vs-dark'));
      };
    
    return (
      <div className="compiler-page">
        <div className="editor-container">
            <h2 className="editor-heading">{title}</h2>
            <div className="control-panel">
        <button 
          className={`theme-toggle-button ${theme === 'vs-dark' ? '' : 'light'}`}
          onClick={toggleTheme}
        >
          {theme === 'vs-dark' ? darkmode : lightmode}
        </button>
        <div>
          <label htmlFor="fontSizeSelect" style={{ marginRight: '10px' }}>{font}</label>
          <select 
            id="fontSizeSelect" 
            value={fontSize} 
            onChange={handleFontSizeChange} 
            className="font-size-select"
          >
            {[8, 10, 12, 14, 16, 18, 20, 22, 24].map(size => (
              <option key={size} value={size}>{size}px</option>
            ))}
          </select>
        </div>
        </div>
            <MonacoEditor
                height="400px"
                width="800px"
                defaultLanguage="python"
                defaultValue={"# Write your Python code here"}
                value={code}
                onChange={handleEditorChange}
                theme={theme}
                options={{
                selectOnLineNumbers: true,
                roundedSelection: false,
                readOnly: false,
                cursorStyle: 'line',
                automaticLayout: true,
                fontSize: fontSize,
                }}
            />
            <div className="button-group">
                <button 
                  onClick={handleRun} 
                  className="run-button"
                  disabled={isRunning}
                >
                  {isRunning ? (
                  <span className="loading-spinner"></span>
                ) : (
                  run
                )}
                </button>
                
                <button 
                  onClick={handleSubmit} 
                  className="submit-button"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                  <span className="loading-spinner"></span>
                ) : (
                  submit
                )}
                  </button>
            </div>
            {renderOutputBox()}
            {renderResultsTable()}
        </div>
      </div>
    );
};

export default CompilerPage;